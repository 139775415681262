<template>
    <div class="row justify-content-center">
        <div class="col-md-6">
            <h3 class="text-center">Update Seller</h3>
            <form @submit.prevent="handleUpdateForm">
                <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="seller.name" required>
                </div>

                <div class="form-group">
                    <label>Email</label>
                    <input type="email" class="form-control" v-model="seller.email" required>
                </div>

                <div class="form-group">
                    <label>Phone</label>
                    <input type="text" class="form-control" v-model="seller.phone" required>
                </div>

                <div class="form-group">
                    <label>Property Address</label>
                    <input type="textarea" class="form-control" v-model="seller.assetaddress" required>
                </div>
                <div class="form-group">
                    <label>Type</label>
                    <input type="text" class="form-control" v-model="seller.assettype" required>
                </div>
                <div class="form-group">
                    <button class="btn btn-danger btn-block">Update</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            seller: { }
        }
    },
    created() {
        const configruntime = require('../configruntime');
        const port = configruntime.apiport;
        let apiURL = `https://needtosell.net/api/edit-seller/${this.$route.params.id}`;
        if (configruntime.environment == 'dev') {
            apiURL = 'http://localhost:' + port + `/api/edit-seller/${this.$route.params.id}`;
        }

        axios.get(apiURL).then((res) => {
            this.seller = res.data;
        })
    },
    methods: {
        handleUpdateForm() {
            const configruntime = require('../configruntime');
            const port = configruntime.apiport;
            let apiURL = `https://needtosell.net/api/update-seller/${this.$route.params.id}`;
            if (configruntime.environment == 'dev') {
                apiURL = 'http://localhost:' + port + `/api/update-seller/${this.$route.params.id}`;
            }

            axios.post(apiURL, this.seller).then((res) => {
                console.log(res)
                this.$router.push('/view')
            }).catch(error => {
                console.log(error)
            });
        }
    }
}
</script>